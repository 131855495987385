<template>
    <div class="min-h-screen md:bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="@/assets/logo.svg" alt="Workflow" />
            <h2 class="mt-6 text-center text-4xl font-bold text-gray-600">
                {{$t('dashboard.management')}}
            </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white p-4 sm:rounded-lg sm:p-10">
                <div v-if="!(restaurants.length >= 2)">
                    <div @keyup.enter="signIn">
                    <input-normal class="mb-5" v-model="mail" inputtype="email" inputname="email" inputid="email" inputplaceholder="moin@delivery-boosting.de">
                        <template #title>{{$t('dashboard.mail')}}</template>
                    </input-normal>
                    </div>
                    <div @keyup.enter="signIn">
                    <input-normal class="mb-10" v-model="password" inputtype="password" inputname="password" inputid="password" inputplaceholder="***********">
                        <template #title>{{$t('dashboard.password')}}</template>
                    </input-normal>
                    </div>
                    <button-b :loading="loading" @click.native="signIn" class="bg-primary w-full justify-center">{{$t('dashboard.login')}}</button-b>
                    </div>
                    <div v-if="errorCode" class="mt-2 text-red">
                        <svg xmlns="http://www.w3.org/2000/svg" class=" h-6 w-6" style="display: inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                        <span class="text-red ml-1">{{ $t(`dashboard.${errorCode}`) }}</span>
                    </div>
                <transition name="slide-fade">
                <div v-if="restaurants.length >= 2" class="space-y-4 block">
                    <strong class="text-black">{{ $t('dashboard.login_multiple_restaurants')}}</strong>
                    <label v-for="restaurant in restaurants" :key="restaurant.uuid" @click="$router.push( { name: 'dashboard-overview', params: { restaurantId: restaurant.uuid}} ), loading = false" class=" hover:bg-gray-100 mt-2 relative block rounded-lg border border-primary bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:justify-between focus-within:ring-1 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <input type="radio" name="server_size" value="Hobby" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1">
                    <div class="flex w-full justify-between content-evenly items-center">
                        <p id="server-size-0-label" class="font-medium text-base text-gray-600">
                            <strong>{{restaurant.generalTitle}}</strong>
                        </p>
                        <img v-if="restaurant.generalMedia[0]" :src="restaurant.generalMedia[0].url" class="object-right h-12 w-auto rounded" alt="">
                    </div>
                    <div class="flex-wrap mt-2" style="font-size: 12px">
                        {{restaurant.generalDescription}}
                    </div>
                    <!-- Checked: "border-indigo-500", Not Checked: "border-transparent" -->
                    <div class="border-transparent absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
                    </label>
                    <button-b @click.native="restaurants=[], mail='', password='', loading=false" class="hover:bg-primary-light text-gray-600 bg-white w-full justify-center border-2 border-primary"><strong class="text-primary">{{$t('dashboard.other_account')}}</strong></button-b>
                </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>

import ButtonB from '@/components/ui/ButtonB.vue'
import InputNormal from '@/components/ui/InputNormal.vue'
import { auth, db, dbc } from '@/firebase'

export default {
    data () {
        return {
            mail: '',
            password: '',
            restaurantIds: [],
            restaurants: [],
            errorCode: '',
            loading: false
        }
    },
    components: {
        ButtonB,
        InputNormal
    },
    methods: {
        async adminCollection () {
            this.loading = true
            const adminSnaphot = await db.collectionGroup('admins').where('uid', '==', auth.currentUser.uid).get()
            this.restaurantIds = adminSnaphot.docs.map(d => d.ref.parent.parent.id)
            if (this.restaurantIds.length === 1) {
                this.$router.push({ name: 'dashboard-overview', params: { restaurantId: this.restaurantIds[0] } })
            }
        },
        async signIn () {
            this.errorCode = ''
            try {
                await auth.signInWithEmailAndPassword(this.mail, this.password)
                this.adminCollection()
            } catch (error) {
                this.errorCode = error.code
            }
        }
    },
    watch: {
        async restaurantIds (restaurantIds) {
            const runner = restaurantIds.map(
                id => dbc.getRestaurant(id)
            )
            this.restaurants = await Promise.all(runner)
        }
    },
    async mounted () {
        setTimeout(() => {
            if (auth.currentUser && auth.currentUser.uid) {
                this.adminCollection()
            }
        }, 500)
    }
}
</script>

<style >

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}

</style>
